import styled from 'styled-components';
import { mq } from './mediaQueries';
import { colors } from './colors';

export const DropdownMenu = styled.div`
  display: block;
  position: absolute;
  z-index: 10;
  top: calc(50% + 10px);
  right: 0px;
  padding: 10px 0;
  margin-top: 10px;
  border-radius: 5px;
  background-color: var(--rs-body);
  border: 1px solid var(--rs-border-primary);
  transform: rotateX(-90deg);
  transform-origin: top center;
  opacity: 0.3;
  transition: 280ms all 120ms ease-out;

  a {
    display: block;
    padding: 10px 25px;
    margin: 0;
  }

  svg {
    margin-left: 5px;
  }

  &:hover {
    opacity: 1;
    transform: rotateX(0);
    visibility: visible;
  }
`;

export const SearchContainer = styled.div`
  display: block;
  position: relative;
  background: var(--i-background-tone);
  margin-bottom: 20px;
  padding: 40px;
  border-radius: 6px;
  border: 1px solid var(--rs-border-primary);

  @media screen and (${mq.mobile}) {
    margin-left: -20px;
    margin-right: -20px;
    border-radius: 0;
    padding: 20px;
  }

  .rs-picker-toggle-wrapper {
    width: 100%;
  }
`;

export const Label = styled.div``;

export const Info = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 10px;
  font-weight: 600;
`;

export const FieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 20px;

  @media screen and (${mq.desktop}) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media screen and (${mq.desktopLarge}) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media screen and (${mq.tablet}) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  @media screen and (${mq.mobile}) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    padding: 0;
  }
`;

export const FieldContainer = styled.div`
  .rs-picker:not(.rs-picker-disabled):hover,
  .rs-picker.rs-picker-focused {
    outline: none !important;
    box-shadow: none !important;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;

  @media screen and (${mq.mobile}) {
    flex-direction: column;
    align-items: center;

    button {
      width: 100%;
    }
  }
`;

export const PriceChange = styled.span<{ $isNegative: boolean }>`
  color: ${(props) => (props.$isNegative ? colors.red : colors.green)};
`;

export const ScreenerTitleContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-size: 2em;

  h1 {
    color: var(--rs-text-heading);
    text-align: left;
    font-size: inherit;
    line-height: 1em;
    font-weight: 500;
    margin-right: auto;
  }
`;
