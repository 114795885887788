import type { FC } from 'react';
import type { WrapPageElementBrowserArgs } from 'gatsby';
import { isBrowser } from '../../src/utils/env';

export const wrapRootElement: FC<WrapPageElementBrowserArgs> = ({
  element,
}) => {
  if (
    isBrowser() &&
    localStorage &&
    localStorage.getItem('VIEW_PROTECTOR') ===
      process.env.GATSBY_VIEW_PROTECTOR_KEY
  ) {
    return <>{element}</>;
  }

  return <></>;
};
